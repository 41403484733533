import React, { useEffect, Suspense } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { useDispatch } from "react-redux";

// import { useThemeContext } from "./context/ThemeProvider";

import LoginPage from "./pages/LoginPage";
import Toast from "./components/Toast/Toast";
import LoadingSpinner from "./components/UI/LoadingSpinner";
import ProtectedRoute from "./components/Authentication/ProtectedRoute";
import { getOneProfile } from "./store/UsersSlice/user-actions";

const HomePage = React.lazy(() => import("./pages/HomePage"));
const ProfilePage = React.lazy(() => import("./pages/ProfilePage"));
const SettingsPage = React.lazy(() => import("./pages/SettingsPage"));
const NewPostPage = React.lazy(() => import("./pages/NewPostPage"));
const PostPage = React.lazy(() => import("./pages/PostPage"));
const SignupPage = React.lazy(() => import("./pages/SignupPage"));

function App() {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getOneProfile());
  }, [dispatch]);

  //const { theme } = useThemeContext();

  return (
    <>
    {/* <div data-theme={theme}> */}
      <Toast />
      <Suspense fallback={<LoadingSpinner />}>
        <Switch>
          <ProtectedRoute path="/" exact>
            <HomePage />
          </ProtectedRoute>
          <Route path="/login" exact>
            <LoginPage />
          </Route>
          <Route path="/register" exact>
            <SignupPage />
          </Route>
          <ProtectedRoute path="/profile/:userName">
            <ProfilePage />
          </ProtectedRoute>
          <ProtectedRoute path="/account/settings">
            <SettingsPage />
          </ProtectedRoute>
          <ProtectedRoute path="/post/new">
            <NewPostPage />
          </ProtectedRoute>
          <ProtectedRoute path="/post/:postId">
            <PostPage />
          </ProtectedRoute>
          <Route path="*">
            <Redirect to="/" />
          </Route>
        </Switch>
      </Suspense>
    {/* </div> */}
    </>
  );
}

export default App;
