import { createSlice } from "@reduxjs/toolkit";
import {
  getAllPosts,
  createNewPost,
  getUserPosts,
  removeUserPost,
  getPostLikes,
  likeOrDislikePost
} from "./post-actions";

const postSlice = createSlice({
  name: "post",
  initialState: {
    loading: true,
    allPosts: [],
    userPosts: [],
    users: [],
    likes: [],
    error: "",
  },
  reducers: {
    clearData(state) {
      state.loading = true;
      state.userPosts = [];
      state.likes=[];
    },
    clearLikes(state){
      state.likes=[];
    }
  },
  extraReducers: {
    [getAllPosts.fulfilled]: (state, action) => {
      state.loading = false;
      state.allPosts = action.payload.posts;
    },
    [getAllPosts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getUserPosts.fulfilled]: (state, action) => {
      state.loading = false;
      state.userPosts = action.payload.posts;
    },
    [getUserPosts.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [createNewPost.fulfilled]: (state) => {
      state.loading = false;
      // state.userPosts = state.userPosts.push(action.payload.post);
    },
    [createNewPost.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [removeUserPost.fulfilled]: (state) => {
      state.loading = false;
    },
    [removeUserPost.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [getPostLikes.fulfilled]: (state, action) => {
      state.loading = false;
      state.likes = action.payload.likes;
    },
    [getPostLikes.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [likeOrDislikePost.fulfilled]: (state) => {
      state.loading = false;
    },
    [likeOrDislikePost.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const postActions = postSlice.actions;
export default postSlice;
