import { configureStore } from "@reduxjs/toolkit";

import userSlice from "./UsersSlice/user-slice";
import followSlice from "./FollowSlice/follow-slice";
import postSlice from "./PostSlice/post-slice";
import postDetailsSlice from "./PostDetailsSlice/post-details-slice";
import toastSlice from "./ToastSlice/toast-slice";

const store = configureStore({
  reducer: {
    user: userSlice.reducer,
    follow: followSlice.reducer,
    post: postSlice.reducer,
    postDetails: postDetailsSlice.reducer,
    toast: toastSlice.reducer,
  },
});

export default store;
