import React from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  FaCheck,
  FaExclamationCircle,
  FaExclamationTriangle,
  FaInfoCircle,
} from "react-icons/fa";
import { AiFillCloseCircle } from "react-icons/ai";
import classes from "./Toast.module.css";
import { toastActions } from "../../store/ToastSlice/toast-slice";

const Toast = () => {
  const dispatch = useDispatch();
  const toasts = useSelector((state) => state.toast.items);
  const timeLimit = 3500;

  const generateIcon = (type) => {
    switch (type) {
      case "INFO":
        return <FaInfoCircle />;
      case "WARNING":
        return <FaExclamationTriangle />;
      case "DANGER":
        return <FaExclamationCircle />;
      case "SUCCESS":
        return <FaCheck />;
      default:
        return;
    }
  };

  const generateBackgroundColor = (type) => {
    switch (type) {
      case "INFO":
        return "hsl(193, 86%, 50%)";
      case "DANGER":
        return "hsl(2, 64%, 58%)";
      case "SUCCESS":
        return "hsl(120, 49%, 54%)";
      default:
        return;
    }
  };
  return (
    <div className={classes[`notification-container`]}>
      {toasts.length > 0 &&
        toasts.map((notification) => {
            setInterval(() => {
              dispatch(toastActions.deleteToast(notification.id));
            }, timeLimit);

          return (
            <div
              style={{
                backgroundColor: generateBackgroundColor(notification.type),
              }}
              key={notification.id}
              className={`${classes.notification} ${classes.toast}`}
            >
              <AiFillCloseCircle
                onClick={() =>
                  dispatch(toastActions.deleteToast(notification.id))
                }
                className={classes[`close-button`]}
              />
              <div className={classes[`notification-image`]}>
                {generateIcon(notification.type)}
              </div>
              <div>
                <p className={classes[`notification-message`]}>
                  {notification.message}
                </p>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Toast;
