import { createSlice } from "@reduxjs/toolkit";

const toastSlice = createSlice({
  name: "toast",
  initialState: {
    items: [],
  },
  reducers: {
    addToast(state, action) {
      state.items = [...state.items, action.payload];
    },
    deleteToast(state, action) {
      state.items = state.items.filter((toast) => toast.id !== action.payload);
    },
  },
});

export const toastActions = toastSlice.actions;

export default toastSlice;
