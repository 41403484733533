import React from "react";
import classes from "./LoadingSpinner.module.css";

const LoadingSpinner = ({ height = "6rem", width = "6rem" }) => {
  return (
    <section className={classes.section}>
      <div style={{ height: `${height}`, width: `${width}` }}></div>
      <div style={{ height: `${height}`, width: `${width}` }}></div>
      <div style={{ height: `${height}`, width: `${width}` }}></div>
      <div style={{ height: `${height}`, width: `${width}` }}></div>
    </section>
  );
};

export default LoadingSpinner;
