import { createSlice } from "@reduxjs/toolkit";
import {
  getUserPost,
  getPostComments,
  createNewComment,
} from "./post-details-actions";

const postDetailsSlice = createSlice({
  name: "post",
  initialState: {
    loading: true,
    post: {},
    comments: [],
    error: "",
  },
  reducers: {
    clearData(state) {
      state.loading = true;
      state.post = {};
      state.comments = [];
    },
  },
  extraReducers: {
    [getUserPost.fulfilled]: (state, action) => {
      state.loading = false;
      state.post = action.payload.post;
    },
    [getUserPost.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getPostComments.fulfilled]: (state, action) => {
      state.loading = false;
      state.comments = action.payload.comments;
    },
    [getPostComments.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [createNewComment.fulfilled]: (state) => {
      state.loading = false;
      // state.userPosts = state.userPosts.push(action.payload.post);
    },
    [createNewComment.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const postDetailActions = postDetailsSlice.actions;
export default postDetailsSlice;
