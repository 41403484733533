import { createAsyncThunk } from "@reduxjs/toolkit";
import { toastActions } from "../ToastSlice/toast-slice";
import { v4 as uuidv4 } from "uuid";

export const getAllProfile = createAsyncThunk(
  "post/getAllProfile",
  async (arg, { rejectWithValue }) => {
    const url = process.env.REACT_APP_DB_URL + `/profile`;
    const token = localStorage.getItem("authTokenIMGRAM");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      return rejectWithValue("Couldn't get profile");
    }
    const data = await response.json();
    return { users: data.message };
  }
);

export const getOneProfile = createAsyncThunk(
  "post/getOneProfile",
  async (userName, { rejectWithValue }) => {
    const url = process.env.REACT_APP_DB_URL + `/profile/${userName}`;
    const token = localStorage.getItem("authTokenIMGRAM");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      return rejectWithValue("Couldn't get profile");
    }
    const data = await response.json();
    return { user: data.message };
  }
);

export const updateOneProfile = createAsyncThunk(
  "post/updateOneProfile",
  async ({ userName, updates }, { rejectWithValue, dispatch }) => {
    dispatch(
      toastActions.addToast({
        id: uuidv4(),
        type: "INFO",
        message: "Updating user profile details",
      })
    );
    const url = process.env.REACT_APP_DB_URL + `/profile/${userName}`;
    const token = localStorage.getItem("authTokenIMGRAM");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ updates }),
    });
    if (!response.ok) {
      dispatch(
        toastActions.addToast({
          id: uuidv4(),
          type: "DANGER",
          message: "Couldn't update the profile",
        })
      );
      return rejectWithValue("Couldn't update profile");
    }
    const storedUser = JSON.parse(localStorage.getItem("userIMGRAM"));
    localStorage.setItem(
      "userIMGRAM",
      JSON.stringify({ ...storedUser, ...updates })
    );
    dispatch(getOneProfile());
    const data = await response.json();
    dispatch(
      toastActions.addToast({
        id: uuidv4(),
        type: "SUCCESS",
        message: "Succesfully updated user profile",
      })
    );
    return { user: data.message,reload:true };
  }
);

export const deleteProfile = createAsyncThunk(
  "post/deleteProfile",
  async (userName, { rejectWithValue, dispatch }) => {
    const url = process.env.REACT_APP_DB_URL + `/profile/${userName}`;
    const token = localStorage.getItem("authTokenIMGRAM");
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      return rejectWithValue("Couldn't delete profile");
    }
    dispatch(getAllProfile());
    const data = await response.json();
    return { status: data.message };
  }
);
