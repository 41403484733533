import { createAsyncThunk } from "@reduxjs/toolkit";
import { getUserPost } from "../PostDetailsSlice/post-details-actions";
import { toastActions } from "../ToastSlice/toast-slice";
import { v4 as uuidv4 } from "uuid";

export const getAllPosts = createAsyncThunk(
  "post/getAllPosts",
  async (arg, { rejectWithValue }) => {
    const url = process.env.REACT_APP_DB_URL + "/post";
    const token = localStorage.getItem("authTokenIMGRAM");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      return rejectWithValue("Couldn't get posts");
    }
    const data = await response.json();
    return { posts: data.message };
  }
);

export const getUserPosts = createAsyncThunk(
  "post/getUserPosts",
  async (userName, { rejectWithValue }) => {
    const url = process.env.REACT_APP_DB_URL + `/${userName}/posts`;
    const token = localStorage.getItem("authTokenIMGRAM");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      return rejectWithValue("Couldn't get user posts");
    }
    const data = await response.json();
    return { posts: data.message };
  }
);

export const createNewPost = createAsyncThunk(
  "post/createNewPost",
  async (postBody, { rejectWithValue, dispatch }) => {
    dispatch(
      toastActions.addToast({
        id: uuidv4(),
        type: "INFO",
        message: "Creating new post",
      })
    );
    const url = process.env.REACT_APP_DB_URL + "/post";
    const token = localStorage.getItem("authTokenIMGRAM");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ postBody }),
    });
    if (!response.ok) {
      dispatch(
        toastActions.addToast({
          id: uuidv4(),
          type: "DANGER",
          message: "Couldn't create new post",
        })
      );
      return rejectWithValue("Couldn't create new post");
    }
    dispatch(getUserPosts());
    dispatch(getAllPosts());
    const data = await response.json();
    dispatch(
      toastActions.addToast({
        id: uuidv4(),
        type: "SUCCESS",
        message: "Created new post",
      })
    );
    return { post: data.message };
  }
);

export const removeUserPost = createAsyncThunk(
  "post/removeUserPost",
  async ({ postId, userName }, { rejectWithValue, dispatch }) => {
    dispatch(
      toastActions.addToast({
        id: uuidv4(),
        type: "INFO",
        message: "Deleting existing post",
      })
    );
    const url = process.env.REACT_APP_DB_URL + `/post/${postId}`;
    const token = localStorage.getItem("authTokenIMGRAM");
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      dispatch(
        toastActions.addToast({
          id: uuidv4(),
          type: "DANGER",
          message: "Couldn't delete post",
        })
      );
      return rejectWithValue("Couldn't remove post");
    }
    dispatch(getUserPosts(userName));
    dispatch(getAllPosts());
    const data = await response.json();
    dispatch(
      toastActions.addToast({
        id: uuidv4(),
        type: "SUCCESS",
        message: "Succesfully deleted post",
      })
    );
    return { status: data.message };
  }
);

export const getPostLikes = createAsyncThunk(
  "post/getPostLikes",
  async (postId, { rejectWithValue }) => {
    const url = process.env.REACT_APP_DB_URL + `/post/likes/${postId}`;
    const token = localStorage.getItem("authTokenIMGRAM");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      return rejectWithValue("Couldn't get post likes");
    }
    const data = await response.json();
    return { likes: data.message };
  }
);

export const likeOrDislikePost = createAsyncThunk(
  "post/likeOrDislikePost",
  async (
    { postId, userName, profileMatch, postMatch },
    { rejectWithValue, dispatch }
  ) => {
    dispatch(
      toastActions.addToast({
        id: uuidv4(),
        type: "INFO",
        message: "Updating post like status",
      })
    );
    const url = process.env.REACT_APP_DB_URL + `/post/likes/${postId}`;
    const token = localStorage.getItem("authTokenIMGRAM");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      dispatch(
        toastActions.addToast({
          id: uuidv4(),
          type: "DANGER",
          message: "Couldn't update like status",
        })
      );
      return rejectWithValue("Couldn't modify like status");
    }
    dispatch(getPostLikes(postId));
    dispatch(getAllPosts());
    postMatch && dispatch(getUserPost(postId));
    profileMatch && dispatch(getUserPosts(userName));
    const data = await response.json();
    dispatch(
      toastActions.addToast({
        id: uuidv4(),
        type: "SUCCESS",
        message: "Succesfully updated like status",
      })
    );
    return { status: data.message };
  }
);
