import React, { useState, useEffect, useCallback, useContext } from "react";
import AuthContext from "./auth-context";

export const useAuth = () => {
  return useContext(AuthContext);
};
let logoutTimer;

const calculateRemainingTime = (expiration) => {
  const currTime = new Date().getTime();
  const expTime = +expiration;
  const remTime = expTime - currTime;
  return remTime;
};

const retrieveStoredTokens = () => {
  const storedToken = localStorage.getItem("authTokenIMGRAM");
  const expiresAt = localStorage.getItem("expiresAtIMGRAM");
  const remainingTime = calculateRemainingTime(expiresAt);

  if (remainingTime < 100) {
    localStorage.removeItem("authTokenIMGRAM");
    localStorage.removeItem("expiresAtIMGRAM");
    return null;
  }
  return { token: storedToken, timeLeft: remainingTime };
};

const AuthProvider = (props) => {
  let initialToken;
  const tokenData = retrieveStoredTokens();
  if (tokenData) {
    initialToken = tokenData.token;
  }

  const [userToken, setUserToken] = useState(initialToken);
  const userLoggedIn = !!userToken;

  const logoutHandler = useCallback(() => {
    setUserToken(null);
    localStorage.removeItem("authTokenIMGRAM");
    localStorage.removeItem("expiresAtIMGRAM");
    localStorage.removeItem("userIMGRAM");

    if (logoutTimer) {
      clearTimeout(logoutTimer);
    }
  }, []);

  const loginHandler = (details) => {
    const { token, expiresAt, firstName, lastName, userName, email, avatar, bio } = details;
    console.log(details);
    setUserToken(token);
    localStorage.setItem("authTokenIMGRAM", token);
    localStorage.setItem("expiresAtIMGRAM", expiresAt);
    localStorage.setItem(
      "userIMGRAM",
      JSON.stringify({
        firstName: firstName,
        lastName: lastName,
        userName: userName,
        avatar: avatar,
        email: email,
        bio: bio
      })
    );

    const remainingTime = calculateRemainingTime(expiresAt);

    logoutTimer = setTimeout(logoutHandler, remainingTime);
  };

  useEffect(() => {
    if (tokenData) {
      logoutTimer = setTimeout(logoutHandler, tokenData.timeLeft);
    }
  }, [tokenData, logoutHandler]);

  const contextValue = {
    token: userToken,
    isLoggedIn: userLoggedIn,
    login: loginHandler,
    logout: logoutHandler,
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
