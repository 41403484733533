import { createSlice } from "@reduxjs/toolkit";
import { getOneProfile, getAllProfile, updateOneProfile, deleteProfile } from "./user-actions";

const userSlice = createSlice({
    name: "user",
    initialState: {
      loading: true,
      user:{},
      users:[],
      error: "",
      reload: false,
    },
    reducers: {
      clearData(state){
        state.loading = true;
        state.user={};
      },
      resetReload(state){
        state.reload = false;
      }
    },
    extraReducers: {
      [getAllProfile.fulfilled]: (state,action) => {
          state.loading = false;
          state.users = action.payload.users;
      },
      [getAllProfile.rejected]: (state,action) => {
          state.loading = false;
          state.error = action.payload;
      },
      [getOneProfile.fulfilled]: (state,action) => {
          state.loading = false;
          state.user = action.payload.user;
      },
      [getOneProfile.rejected]: (state,action) => {
          state.loading = false;
          state.error = action.payload;
      },
      [updateOneProfile.fulfilled]: (state,action) => {
        state.loading = false;
        state.signedInUser = action.payload.user;
        state.reload = action.payload.reload;
      },
      [updateOneProfile.rejected]: (state,action) => {
          state.loading = false;
          state.error = action.payload;
      },
      [deleteProfile.fulfilled]: (state) => {
        state.loading = false;
        state.signedInUser = {};
      },
      [deleteProfile.rejected]: (state,action) => {
          state.loading = false;
          state.error = action.payload;
      },
    },
  });
  
  export const userActions = userSlice.actions;
  export default userSlice;
  