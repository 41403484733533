export async function signUp(userInfo) {
  const url = process.env.REACT_APP_DB_URL + "/auth/register";
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(userInfo),
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.error || "could not register user");
  }

  const token = data.token;
  const expiresAt = data.expiresAt;
  const firstName = data.firstName;
  const lastName = data.lastName;
  const userName = data.userName;
  const email = data.email;
  const avatar = data.avatar;
  const bio = data.bio;

  return { token, expiresAt, firstName, lastName, userName, email, avatar, bio };
}

export async function logIn(userInfo) {
  const { email, password } = userInfo;
  const url = process.env.REACT_APP_DB_URL + "/auth/login";
  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, password }),
  });
  const data = await response.json();

  if (!response.ok) {
    throw new Error(data.error || "Invalid credentials!");
  }

  const token = data.token;
  const expiresAt = data.expiresAt;
  const firstName = data.firstName;
  const lastName = data.lastName;
  const userName = data.userName;
  const userEmail = data.email;
  const avatar = data.avatar;
  const bio = data.bio;

  return { token, expiresAt, firstName, lastName, userName, email: userEmail, avatar, bio };
}

export async function saveToCloudinary(formData) {
  const url = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_NAME}/image/upload`;
  const response = await fetch(url, {
    method: "post",
    body: formData,
  });
  const data = await response.json();
  return { id: data.public_id, fileName: data.original_filename };
}
