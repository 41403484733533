import { createAsyncThunk } from "@reduxjs/toolkit";
import { toastActions } from "../ToastSlice/toast-slice";
import { v4 as uuidv4 } from "uuid";

export const getFollowers = createAsyncThunk(
  "post/getFollowers",
  async (userName, { rejectWithValue }) => {
    const url = process.env.REACT_APP_DB_URL + `/profile/${userName}/followers`;
    const token = localStorage.getItem("authTokenIMGRAM");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      return rejectWithValue("Couldn't get followers");
    }
    const data = await response.json();
    return { followers: data.message };
  }
);

export const followOrUnfollow = createAsyncThunk(
  "post/followOrUnfollow",
  async ({ userName, viewer }, { rejectWithValue, dispatch }) => {
    dispatch(
      toastActions.addToast({
        id: uuidv4(),
        type: "INFO",
        message: "Handle follow list update",
      })
    );
    const url = process.env.REACT_APP_DB_URL + `/profile/${userName}/followers`;
    const token = localStorage.getItem("authTokenIMGRAM");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      dispatch(
        toastActions.addToast({
          id: uuidv4(),
          type: "DANGER",
          message: "Couldn't complete follow list update",
        })
      );
      return rejectWithValue("Couldn't complete action");
    }
    dispatch(getFollowers(viewer));
    dispatch(getFollowing(userName));
    const data = await response.json();
    dispatch(
      toastActions.addToast({
        id: uuidv4(),
        type: "SUCCESS",
        message: "Handled follow list update",
      })
    );
    return { status: data.isFollowed };
  }
);

export const getFollowing = createAsyncThunk(
  "post/getFollowing",
  async (userName, { rejectWithValue }) => {
    const url = process.env.REACT_APP_DB_URL + `/profile/${userName}/following`;
    const token = localStorage.getItem("authTokenIMGRAM");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      return rejectWithValue("Couldn't get following");
    }
    const data = await response.json();
    return { following: data.message };
  }
);

export const removeFromFollowing = createAsyncThunk(
  "post/removeFromFollowing",
  async ({ userName, viewer }, { rejectWithValue, dispatch }) => {
    dispatch(
      toastActions.addToast({
        id: uuidv4(),
        type: "INFO",
        message: "Handle follower list update",
      })
    );
    const url = process.env.REACT_APP_DB_URL + `/profile/${userName}/following`;
    const token = localStorage.getItem("authTokenIMGRAM");
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      dispatch(
        toastActions.addToast({
          id: uuidv4(),
          type: "DANGER",
          message: "Couldn't complete follower list update",
        })
      );
      return rejectWithValue("Couldn't complete action");
    }
    dispatch(getFollowing(viewer));
    dispatch(getFollowers(userName));
    const data = await response.json();
    dispatch(
      toastActions.addToast({
        id: uuidv4(),
        type: "SUCCESS",
        message: "Handled followers list update",
      })
    );
    return { status: data.isFollowed };
  }
);
