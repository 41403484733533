import { createSlice } from "@reduxjs/toolkit";
import {
  getFollowers,
  followOrUnfollow,
  getFollowing,
  removeFromFollowing,
} from "./follow-actions";

const followSlice = createSlice({
  name: "follow",
  initialState: {
    loading: true,
    followers: [],
    following: [],
    error: "",
  },
  reducers: {
    clearData(state) {
      state.loading = true;
      state.followers=[];
      state.following=[];
    },
  },
  extraReducers: {
    [getFollowers.fulfilled]: (state, action) => {
      state.loading = false;
      state.followers = action.payload.followers;
    },
    [getFollowers.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },

    [followOrUnfollow.fulfilled]: (state) => {
      state.loading = false;
    },
    [followOrUnfollow.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [getFollowing.fulfilled]: (state, action) => {
      state.loading = false;
      state.following = action.payload.following;
    },
    [getFollowing.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    [removeFromFollowing.fulfilled]: (state) => {
      state.loading = false;
    },
    [removeFromFollowing.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const followActions = followSlice.actions;
export default followSlice;
