import React, { useContext, useState, useEffect } from "react";

function getInitialColorMode() {
  const persistedColorPreference = window.localStorage.getItem("themeIMGRAM");
  const hasPersistedPreference = typeof persistedColorPreference === "string";

  if (hasPersistedPreference) {
    return persistedColorPreference;
  }

  const mql = window.matchMedia("(prefers-color-scheme: dark)");
  const hasMediaQueryPreference = typeof mql.matches === "boolean";

  if (hasMediaQueryPreference) {
    return mql.matches ? "dark" : "light";
  }

  return "light";
}

const ThemeContext = React.createContext();
export const useThemeContext = () => useContext(ThemeContext);

export const ThemeProvider = ({ children }) => {
  const [theme, setTheme] = useState(getInitialColorMode);
  
  useEffect(() => {
    document.documentElement.setAttribute("data-theme", theme);
  }, [theme]);

  const themeHandler = (value) => {
    setTheme(value);
    window.localStorage.setItem("themeIMGRAM", value);
  };

  return (
    <ThemeContext.Provider value={{ theme, setTheme: themeHandler }}>
      {children}
    </ThemeContext.Provider>
  );
};
