import { createAsyncThunk } from "@reduxjs/toolkit";
import { toastActions } from "../ToastSlice/toast-slice";
import { v4 as uuidv4 } from "uuid";

export const getUserPost = createAsyncThunk(
  "postDetails/getPost",
  async (postId, { rejectWithValue }) => {
    const url = process.env.REACT_APP_DB_URL + `/post/${postId}`;
    const token = localStorage.getItem("authTokenIMGRAM");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      return rejectWithValue("Couldn't get post details");
    }
    const data = await response.json();
    return { post: data.message };
  }
);

export const getPostComments = createAsyncThunk(
  "postDetails/getPostComments",
  async (postId, { rejectWithValue }) => {
    const url = process.env.REACT_APP_DB_URL + `/comment/${postId}`;
    const token = localStorage.getItem("authTokenIMGRAM");
    const response = await fetch(url, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    if (!response.ok) {
      return rejectWithValue("Couldn't get user posts");
    }
    const data = await response.json();
    return { comments: data.message };
  }
);

export const createNewComment = createAsyncThunk(
  "postDetails/createNewComment",
  async ({ postId, message }, { rejectWithValue, dispatch }) => {
    dispatch(
      toastActions.addToast({
        id: uuidv4(),
        type: "INFO",
        message: "Adding new user comment",
      })
    );
    const url = process.env.REACT_APP_DB_URL + `/comment/${postId}`;
    const token = localStorage.getItem("authTokenIMGRAM");
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify({ comment: message }),
    });
    if (!response.ok) {
      dispatch(
        toastActions.addToast({
          id: uuidv4(),
          type: "DANGER",
          message: "Couldn't add the cmment",
        })
      );
      return rejectWithValue("Couldn't create new post");
    }
    dispatch(getPostComments(postId));
    const data = await response.json();
    dispatch(
      toastActions.addToast({
        id: uuidv4(),
        type: "SUCCESS",
        message: "Succesfully added new comment",
      })
    );
    return { post: data.message };
  }
);
