export const emailValidation = (value) => {
    const regexp =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regexp.test(value);
  };
  
  export const passwordValidation = (value) => {
    const regexp = /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{6,})/;
    return regexp.test(value);
  };
  
  
export const usernameValidation = (value) => {
  const regexp = /^(?!\s*$).{2,20}/;
  return regexp.test(value);
};